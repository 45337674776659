import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"

import CTABlock from "../../components/blocks/cta_block"
import NewsBlock from "../../components/blocks/news_block/"
import moment from "moment"

const NewsPage = ({ data }) => {
  console.log(data)
  return (
    <Layout parent={"news"} siteTitle={"News"}>
      <section className="breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb nav-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="about-area main-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>{data.wagtail.page.title}</h1>
              <p>{data.wagtail.page.descriptionSimple}</p>
            </div>
          </div>
        </div>
      </section>
      <NewsBlock
        data={data.wagtail.page.children.filter(
          item =>
            item.live &&
            moment(item.publishDate.split("+")[0]).isBefore(new Date())
        )}
      />
      <CTABlock data={data.wagtail.page.fullContent[0]} />
    </Layout>
  )
}

export const query = graphql`
  {
    wagtail {
      page(slug: "news") {
        ... on NewsPage {
          title
          descriptionSimple
          children {
            ... on NewPage {
              title
              slug
              categoryName
              date
              detail
              live
              isProtected
              publishDate
              feedImage {
                src
                srcSet(sizes: 800)
              }
            }
          }
          fullContent {
            ... on CTABlock {
              id
              title
              image {
                ... on ImageObjectType {
                  src
                  file
                  width
                  height
                }
              }
              description
              orientation
              url
              urlName
            }
          }
        }
      }
    }
  }
`
export default NewsPage
