import React, { useState, useCallback, useEffect } from "react"

import { Link, StaticQuery, graphql } from "gatsby"
import Moment from "moment"
import Select from "../../Select/index"
import Loader from "react-loader-spinner"

const NewsBlock = (props) => {
  const [filter, setFilter] = useState()
  const [data, setData] = useState(() =>
    props.data.sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
  )
  const [initialData] = useState(
    props.data.sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
  )
  const [text, setText] = useState()
  const [loadMore, setLoadMore] = useState(20)
  const [loading, setLoading] = useState()

  const search = useCallback(
    (event) => {
      if (!event.target.value) {
        setText("")
        return
      }
      setText(event.target.value)
    }, []
  )

  const onClick = useCallback(() => {
    const min = 0.5;
      const max = 1.8
    const rand = Math.floor(Math.random() * (max - min + 0.7) + min)
    setLoading(true)
    setTimeout(
      function () {
        setLoadMore(loadMore + 20)
        setLoading(false)
      },
      rand * 1000
    )
  }, [loading, loadMore])

  const getLoadMore = useCallback(() => {
    const total = data ? data.length : 0
    if (total < 20) {
      return null
    }
    if (loadMore >= total) {
      return <></>
    }
    return (
      <button onClick={onClick} className="button-load-more">
        Load More
      </button>
    )
  }, [onClick, loadMore, data])

  useEffect(() => {
    Moment.locale("en")
  }, [])

  const handleChange = useCallback(({name, value}) => {
    setFilter(value)
  }, [])

  useEffect(() => {
    let _data = initialData.filter(
          (item) =>
            filter === undefined ||
            filter === "all" ||
            item.categoryName.toLowerCase() === filter
        )
        _data = _data.filter(
          (item) =>
            !text ||
            item.detail.toLowerCase().includes(text.toLowerCase()) ||
            item.title.toLowerCase().includes(text.toLowerCase())
        )
    setData(_data)
  }, [initialData, text, filter])

  return (
    <>
      <div className="main-area about-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="select-items-area office">
                {" "}
                <div className="box">
                  <p className="p-bold pt-30 pb-10">Category</p>
                  <Select
                    style={{ width: "100%" }}
                    name="filter"
                    placeholder="All"
                    value={filter}
                    handleChange={handleChange}
                    selectOptions={[ "all", ...props.combos.wagtail.setting.mainCategoriesItems.map(
                      (item) => item.categoryName.toLowerCase()
                    ) ]}
                  />
                </div>
                <div className="box" style={{marginTop: "-9px"}}>
                  <p className="p-bold pt-30 pb-10">Search</p>
                  <div className="search-box">
                    <input
                      type="search"
                      placeholder="Enter keyword"
                      value={text}
                      onChange={search}
                    />
                    <button>
                      <img src={`../../images/search.png`} alt="src" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-personal-area">
        <div className="container">
          <div className="row">
            {data.map((item, index) => {
              if (index < loadMore) {
                return (
                  <div key={index.toString()} className="col-md-4 col-sm-6">
                    <div className="latest-news">
                      <div className="latest-news-item">
                        <div className="latest-news-item-layer"></div>
                        <div className="zoom">
                          <img
                            src={item.feedImage ? item.feedImage.src : ""}
                            srcSet={item.feedImage ? item.feedImage.srcSet : ""}
                            width="100%"
                            height="196px"
                            style={{
                              backgroundColor: item.feedImage
                                ? "none"
                                : "#A7A8AA",
                            }}
                            alt={item.tile}
                          />
                        </div>
                        <div className="latest-news-content">
                          <h5>{item.categoryName}</h5>
                          <p className="p-legal mt-15 mb-15">
                            {Moment(item.date).format("MMMM D, YYYY")}
                          </p>
                          <h3>{item.title}</h3>
                          <p className="p-legal p-body">{item.detail}</p>
                        </div>
                        <Link to={`/news/${item.slug}/`} className="read-more">
                          Read more{" "}
                          <img
                            className="arrow arrow-right"
                            src={`../images/arrow-right-2.png`}
                            alt="arrow"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              }
              return ""
            })}
          </div>
          <div className="row">
            <div className="col-12 align-items-center text-center">
              {loading && (
                <>
                  <Loader
                    type="Circles"
                    color="#00aad8"
                    height={120}
                    width={120}
                  />
                  <br />
                </>
              )}
              {getLoadMore()}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default function MyNewsBlock(props) {
  return (
    <StaticQuery
      query={graphql`
        {
          wagtail {
            setting(name: "CategoriesSettings") {
              ... on CategoriesSettings {
                id
                mainCategoriesItems {
                  categoryName
                  id
                }
              }
            }
          }
        }
      `}
      render={(data) => <NewsBlock combos={data} {...props} />}
    />
  )
}
